import { Injectable } from '@angular/core';
import { RippleAnimationConfig, RippleGlobalOptions } from '@angular/material/core';

@Injectable({ providedIn: 'root' })
export class AppGlobalRippleOptions implements RippleGlobalOptions {
    /** 
     * Whether ripples should be disabled globally.
     */
    disabled: boolean = true;
    animation: RippleAnimationConfig = {
        enterDuration: 0,
        exitDuration: 0,
    }
}
