<div class="date-time-input">
       <input #dayInput class="day ace-input"
              type="text"
              autocomplete="off"
              placeholder="YYYY-MM-DD"
              [matDatepicker]="picker"
              (dateInput)="fireChange()"
              (keyup)="fireChange()"
              (paste)="processPaste($event)">
       <div class="cal">
              <mat-icon class="icon14" (click)="picker.open()">calendar_today</mat-icon>
       </div>
       <mat-datepicker #picker></mat-datepicker>
       <input #hourInput class="hh ace-input"
              type="text"
              placeholder="hh"
              (keyup)="fireChange()"
              (change)="fireChange()"
              (paste)="processPaste($event)">
       <input #minuteInput class="mm ace-input"
              type="text"
              placeholder="mm"
              (keyup)="fireChange()"
              (change)="fireChange()"
              (paste)="processPaste($event)">
       <input #secondInput class="ss ace-input"
              type="text"
              placeholder="ss"
              (keyup)="fireChange()"
              (change)="fireChange()"
              (paste)="processPaste($event)">
</div>
