import { HttpParams } from "@angular/common/http";

export function convertFormValueToHttpParams(formValue: { [key: string]: any }) {
    let params = new HttpParams();
    for (const k in formValue) {
        const v = formValue[k];
        if (v && v !== '__ANY') {
            params = params.append(k, v);
        }
    }
    return params;
}

// Format in local time
export function formatYYYYMMDD(date: Date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

export function toDate(obj: any): Date {
    if (!obj) {
        return obj;
    }

    if (obj instanceof Date) {
        return obj;
    } else if (typeof obj === 'number') {
        return new Date(obj);
    } else if (typeof obj === 'string') {
        if (!obj.endsWith('Z') && obj.indexOf('+') === -1) {
            obj = obj + 'Z';
        }
        return new Date(Date.parse(obj));
    } else {
        throw new Error(`Cannot convert '${obj}' to Date`);
    }
}

export function printDateTime(date: Date | string, addTimezone = true): string {
    let dateString;
    if (typeof date === 'string') {
        // Convert to date first, this standardizes output (millis precision)
        dateString = toDate(date).toISOString();
    } else {
        dateString = date.toISOString();
    }
    return dateString.replace('T', ' ').replace('Z', addTimezone ? ' UTC' : '');
}
