<div style="width: 100%; text-align: center">
  <div class="frame" [style.marginTop]="marginTop">
    <div class="header" *ngIf="headerTitle">
      {{ headerTitle }}
    </div>
    <div class="body">
      <ng-content />
    </div>
  </div>
</div>
