import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { User } from '../client/model';
import { AceComponent } from '../shared/page/ace-component';
import { AuthService } from '../shared/services/auth.service';
import { ChangeEmailDialogComponent } from './change-email-dialog/change-email-dialog';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog';

@Component({
    templateUrl: './profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent extends AceComponent {

    user$ = inject(AuthService).user$.asObservable();

    openChangePasswordDialog(user: User) {
        this.dialog.open(ChangePasswordDialogComponent, {
            width: '600px',
            data: { user },
        }).afterClosed().subscribe(password => {
            if (password) {
                this.mwlClient.patchOwnUser({
                    password,
                }).subscribe({
                    next: user => {
                        this.authService.user$.next(user);
                        this.messageService.showInfo('Password changed');
                    },
                    error: err => this.messageService.showError(err),
                });
            }
        });
    }

    openChangeEmailDialog(user: User) {
        this.dialog.open(ChangeEmailDialogComponent, {
            width: '600px',
            data: { user },
        });
    }
}
