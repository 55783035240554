import { Component, Input } from '@angular/core';

@Component({
  selector: 'ace-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.css'],
})
export class InfoMessageComponent {

  @Input()
  headerTitle?: string;
}
