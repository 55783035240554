import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { MwlClient } from '../../client/MwlClient';
import { Quota } from '../../client/model';
import { MessageService } from './MessageService';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {

    private authService = inject(AuthService);

    public cartCount$ = new BehaviorSubject<number>(0);
    public quota$ = new BehaviorSubject<Quota | null>(null);

    private mwlClient = inject(MwlClient);
    private messageService = inject(MessageService);

    constructor() {
        // Establish the first item count, as soon as the user is logged in.
        // Further updates are triggered externally.
        this.authService.user$.pipe(
            filter(user => user !== null && user !== undefined),
            take(1),
        ).subscribe(() => {
            this.refreshQuota();
            this.refreshCartItemCount();
        });
    }

    updateCartCount(cartCount: number) {
        this.cartCount$.next(cartCount);
    }

    refreshQuota() {
        this.mwlClient.getQuota().subscribe({
            next: quota => this.quota$.next(quota),
            error: err => this.messageService.showError(err),
        });
    }

    refreshCartItemCount() {
        this.mwlClient.getCartItems().subscribe({
            next: items => this.cartCount$.next(items.length),
            error: err => this.messageService.showError(err),
        });
    }
}
