<div class="page-sidebar">
    <div class="menu">
        <p style="text-align: center">
            <a class="logo-link" routerLink="/">
                <img src="assets/aces_bw.png">
            </a>
        </p>

        <br>
        <div class="grouper">Product Catalog</div>
        <a routerLink="/product-catalog/catalog-browser" routerLinkActive="active">Catalog Browser</a><br>
        <a routerLink="/product-catalog/catalog-search" routerLinkActive="active">Catalog Search</a><br>
        <ng-container *ngIf="hasPrivilege('ADVANCED_QUERY')">
            <a routerLink="/product-catalog/advanced-catalog-search" routerLinkActive="active">
                Advanced Catalog Search
            </a><br>
        </ng-container>
        <ng-container *ngIf="arch && hasPrivilege('MWL_OPERATE')">
            <a routerLink="/product-catalog/change-requests" routerLinkActive="active">Change Requests</a><br>
        </ng-container>
        <a routerLink="/orders/ordered" routerLinkActive="active">Product Orders</a><br>

        <ng-container
                      *ngIf="hasAnyPrivilege('DL_TM_PACKET', 'DL_TM_PARAM', 'DL_CMD_HISTORY', 'DL_EVENT', 'AUTOMATED_ORDER')">
            <div class="grouper">Order Generation</div>
            <ng-container *ngIf="hasPrivilege('DL_TM_PACKET')">
                <a routerLink="/orders/tm-packets"
                   routerLinkActive="active">TM Packets</a><br>
            </ng-container>
            <ng-container *ngIf="hasPrivilege('DL_TM_PARAM')">
                <a routerLink="/orders/tm-parameters"
                   routerLinkActive="active">TM Parameters</a><br>
            </ng-container>
            <ng-container *ngIf="hasPrivilege('DL_CMD_HISTORY')">
                <a routerLink="/orders/command-history"
                   routerLinkActive="active">Command History</a><br>
            </ng-container>
            <ng-container *ngIf="hasPrivilege('DL_EVENT')">
                <a routerLink="/orders/event-database"
                   routerLinkActive="active">Event Database</a><br>
            </ng-container>
            <ng-container *ngIf="hasPrivilege('AUTOMATED_ORDER')">
                <a routerLink="/orders/automated-orders"
                   routerLinkActive="active">Automated Orders</a><br>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
            <div class="grouper">GS Monitoring</div>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
                <a routerLink="/gs-monitoring/system-tasks" routerLinkActive="active">System Tasks</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')">
                <a routerLink="/gs-monitoring/product-transfer" routerLinkActive="active">Product Transfer</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')">
                <a routerLink="/gs-monitoring/pharao-flow" routerLinkActive="active">PHARAO Flow</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
                <a routerLink="/gs-monitoring/dropboxes" routerLinkActive="active">Dropboxes</a><br>
            </ng-container>
            <ng-container *ngIf="hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
                <a routerLink="/gs-monitoring/mwlgt-stations" routerLinkActive="active">MWL GT Network</a><br>
            </ng-container>
            <ng-container *ngIf="hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
                <a routerLink="/gs-monitoring/slr-stations" routerLinkActive="active">ELT SLR Network</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')">
                <a routerLink="/orders/statistics" routerLinkActive="active">Order Statistics</a><br>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')) || hasPrivilege('MWL_VIEW')">
            <div class="grouper">Planning</div>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')">
                <a routerLink="/planning/preparation" routerLinkActive="active">Planning Preparation</a><br>
            </ng-container>
            <ng-container *ngIf="hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
                <a routerLink="/planning/released" routerLinkActive="active">Released Planning</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
                <a routerLink="/planning/comparison" routerLinkActive="active">Planning Comparison</a><br>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'L4_UPLOAD', 'MWL_OPERATE')">
            <div class="grouper">Science</div>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')">
                <a routerLink="/science/software-descriptions" routerLinkActive="active">Software</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE')">
                <a routerLink="/science/experiments" routerLinkActive="active">Experiments</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasPrivilege('L4_UPLOAD')">
                <a routerLink="/science/publications" routerLinkActive="active">Publications</a><br>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="arch && hasPrivilege('ADMINISTRATE')">
            <div class="grouper">Administration</div>
            <ng-container *ngIf="arch && hasPrivilege('ADMINISTRATE')">
                <a routerLink="/administration/settings" routerLinkActive="active">App Settings</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasPrivilege('ADMINISTRATE')">
                <a routerLink="/administration/yamcs-instances" routerLinkActive="active">Yamcs Instances</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasPrivilege('ADMINISTRATE')">
                <a routerLink="/administration/user-activity" routerLinkActive="active">User Activity</a><br>
            </ng-container>
            <ng-container *ngIf="arch && hasPrivilege('ADMINISTRATE')">
                <a routerLink="/administration/user-admin" routerLinkActive="active">User Administration</a><br>
            </ng-container>
        </ng-container>
    </div>
    <div class="cart">
        <a routerLink="/orders/cart">
            <mat-icon style="vertical-align: middle">shopping_basket</mat-icon>
            &nbsp;&nbsp;
            <ng-container *ngIf="itemCount$ | async as itemCount">
                <ng-container *ngIf="itemCount === 1">1 order in cart</ng-container>
                <ng-container *ngIf="itemCount > 1">{{ itemCount }} orders in cart</ng-container>
            </ng-container>
            <ng-container *ngIf="!(itemCount$ | async)">0 orders in cart</ng-container>
        </a>
    </div>
</div>
