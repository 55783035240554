import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MwlClient } from '../../client/MwlClient';
import { Settings } from '../../client/model';

@Injectable()
export class ConfigService {

    private mwlClient = inject(MwlClient);

    private config: { [key: string]: string; };
    private settings: Settings;

    async loadWebsiteConfig() {
        this.config = await firstValueFrom(this.mwlClient.getConfig());
        this.settings = await firstValueFrom(this.mwlClient.getSettings());
    }

    getConfig() {
        return this.config;
    }

    getProperty(key: string) {
        return this.config[key];
    }

    getBooleanProperty(key: string) {
        return this.config[key] === 'true';
    }

    getSettings() {
        return this.settings;
    }
}
