<div class="dialog-container"
     [class.fill-height]="fillHeight"
     [class.fit-content]="!fillHeight">

    <mat-toolbar class="header">
        <mat-toolbar-row>
            {{ dialogTitle }}
            <ng-content select="ace-dialog-header" />
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="dialog-content" [class.nopadding]="nopadding">
        <ng-content />
    </div>

    <mat-toolbar class="footer">
        <mat-toolbar-row>
            <ng-content select="ace-dialog-footer" />
        </mat-toolbar-row>
    </mat-toolbar>
</div>
