import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { Settings } from 'luxon';
import { AppComponent } from './app.component';
import { ChangeEmailDialogComponent } from './profile/change-email-dialog/change-email-dialog';
import { ChangePasswordDialogComponent } from './profile/change-password-dialog/change-password-dialog';
import { ProfileComponent } from './profile/profile.component';
import { AppGlobalRippleOptions } from './ripple.options';
import { authGuardChildFn, authGuardFn } from './shared/guards/auth.guard';
import { ConfigService } from './shared/services/ConfigService';
import { SharedModule } from './shared/shared.module';

Settings.defaultZone = 'utc';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'product-catalog',
}, {
  path: 'product-catalog',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  loadChildren: () => import('src/app/product-catalog/product-catalog.module').then(m => m.ProductCatalogModule),
}, {
  path: 'administration',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  loadChildren: () => import('src/app/administration/administration.module').then(m => m.AdministrationModule),
}, {
  path: 'gs-monitoring',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  loadChildren: () => import('src/app/gs-monitoring/gs-monitoring.module').then(m => m.GsMonitoringModule),
}, {
  path: 'orders',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  loadChildren: () => import('src/app/orders/orders.module').then(m => m.OrdersModule),
}, {
  path: 'planning',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  loadChildren: () => import('src/app/planning/planning.module').then(m => m.PlanningModule),
}, {
  path: 'science',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  loadChildren: () => import('src/app/science/science.module').then(m => m.ScienceModule),
}, {
  path: 'profile',
  canActivate: [authGuardFn],
  canActivateChild: [authGuardChildFn],
  component: ProfileComponent,
}];

export const matTooltipOptions: MatTooltipDefaultOptions = {
  ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [
    AppComponent,
    ChangeEmailDialogComponent,
    ChangePasswordDialogComponent,
    ProfileComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    SharedModule,
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  providers: [
    ConfigService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipOptions },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: AppGlobalRippleOptions },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => {
        return () => configService.loadWebsiteConfig();
      },
      multi: true,
      deps: [ConfigService]
    },
  ],
  exports: [
    SharedModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
