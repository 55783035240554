import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ace-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {

    @Input()
    padding = true;

    @Input()
    nosidebar = false;
}
