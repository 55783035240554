import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ace-dialog-header',
    template: '&nbsp;&nbsp;<ng-content />',
    styles: [
        ':host { line-height: 0 }',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
}
