import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PrivilegeType, User } from '../../client/model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public user$ = new BehaviorSubject<User | null | undefined>(undefined);
    private httpClient = inject(HttpClient);

    async login() {
        if (this.user$.value === undefined) {
            try {
                const user = await lastValueFrom(this.httpClient.get<User>('/mwl-ui/api/user'));
                this.user$.next(user);
                return true;
            } catch (err) {
                this.user$.next(null);
                return false;
            }
        } else {
            return this.user$.value !== null;
        }
    }

    isLoggedIn() {
        return this.user$.value;
    }

    getUser() {
        return this.user$.value;
    }

    hasPrivilege(privilege: PrivilegeType) {
        const user = this.getUser();
        if (user && user.privileges.indexOf(privilege) !== -1) {
            return true;
        }
        return false;
    }

    hasAnyPrivilege(...privileges: PrivilegeType[]) {
        const user = this.getUser();
        if (user) {
            for (const privilege of privileges) {
                if (user.privileges.indexOf(privilege) !== -1) {
                    return true;
                }
            }
        }
        return false;
    }

    logout() {
        this.clearCookie();
        this.user$.next(null);
        window.location.href = '/mwl-ui/auth';
    }

    private clearCookie() {
        document.cookie = 'aces-session=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    }
}
