import { Component, Input } from '@angular/core';
import { Status } from '../../../client/model';

@Component({
    selector: 'ace-status-icon',
    templateUrl: './status-icon.component.html',
    styleUrls: ['./status-icon.component.css'],
})
export class StatusIconComponent {

    @Input()
    status: Status;

    @Input()
    tooltip?: string;
}
