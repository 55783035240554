import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { EventStreamService } from '../services/EventStreamService';
import { AceComponent } from './ace-component';

@Component({
    selector: 'ace-page-toolbar',
    templateUrl: './page-toolbar.component.html',
    styleUrls: ['./page-toolbar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageToolbarComponent extends AceComponent {

    private statusService = inject(EventStreamService);

    @Input()
    noborder = false;

    @Input()
    grey = false;

    statusInformation$ = this.statusService.statusInformation$.asObservable();
    user$ = this.authService.user$.asObservable();
}
