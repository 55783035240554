import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AceComponent } from '../../shared/page/ace-component';

const PASSWORD_VALIDATOR: ValidatorFn = (control: AbstractControl) => {
    const pw1 = control.get('password')!.value;
    const pw2 = control.get('passwordConfirmation')!.value;
    return pw1 && pw2 && pw1 !== pw2 ? { 'passwordMismatch': true } : null;
};

@Component({
    templateUrl: './change-password-dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordDialogComponent extends AceComponent {

    private data = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<ChangePasswordDialogComponent>);

    form: FormGroup;

    constructor() {
        super();
        this.form = new FormGroup({
            'password': new FormControl('', Validators.required),
            'passwordConfirmation': new FormControl('', Validators.required),
        });
        this.form.addValidators(PASSWORD_VALIDATOR);
    }

    doChange() {
        this.dialogRef.close(this.form.get('password')!.value);
    }
}
