<div class="status-bar">
  <div class="status-bar-content" *ngIf="statusInformation$ | async as info">
    <ng-container *ngIf="arch && hasAnyPrivilege('ADMINISTRATE', 'MWL_OPERATE', 'MWL_VIEW')">
      <a routerLink="/gs-monitoring/product-transfer/dac">
        <ace-status [status]="info.syrteStatus" label="DAC" [leftMargin]="false" />
      </a>
      <a routerLink="/gs-monitoring/product-transfer/eds">
        <ace-status [status]="info.edsFilesStatus" label="EDS" />
      </a>
      <a routerLink="/gs-monitoring/product-transfer/eltdc">
        <ace-status [status]="info.eltDcStatus" label="ELT-DC" />
      </a>
      <a routerLink="/gs-monitoring/product-transfer/fs">
        <ace-status [status]="info.acesFsStatus" label="FS" />
      </a>
      <a routerLink="/gs-monitoring/product-transfer/oc">
        <ace-status [status]="info.ocStatus" label="OC" />
      </a>
      <a routerLink="/gs-monitoring/product-transfer/pharaows">
        <ace-status [status]="info.pharaowsStatus" label="PHARAO WS" />
      </a>
      <a routerLink="/gs-monitoring/mwlgt-stations">
        <ace-status [status]="info.mwlGtStatus" label="MWL GT" />
      </a>
      <a routerLink="/gs-monitoring/product-transfer/pldevel">
        <ace-status [status]="info.pldevelStatus" label="PL DEVEL" />
      </a>
      <span class="status-grouper">&nbsp;&nbsp;&nbsp;Dropboxes:</span>
      <a routerLink="/gs-monitoring/dropboxes/igs">
        <ace-status [status]="info.igsDropboxStatus" label="IGS" />
      </a>
      <a routerLink="/gs-monitoring/dropboxes/eds">
        <ace-status [status]="info.edsDropboxStatus" label="EDS" />
      </a>
    </ng-container>

    <span style="flex: 1 1 auto"></span>

    <div class="right-side">
      <a href="/yamcs/" mat-button color="primary">
        Yamcs
      </a>
      &nbsp;&nbsp;
      <a href="/dp/" mat-button color="primary">
        Data Processing
      </a>
      &nbsp;/&nbsp;
      M&amp;C v{{ config['quarkus.application.version'] }} {{ config['mwl.aces.env' ]}}
      <ng-container *ngIf="user$ | async as user">
        &nbsp;/&nbsp;
        <button mat-button color="primary" [matMenuTriggerFor]="userMenu">
          <mat-icon>support_agent</mat-icon>
          {{ user.displayName || user.username }} ▾
        </button>
        <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
          <a routerLink="/profile" mat-menu-item>
            Profile
          </a>
          <mat-divider />
          <a href="/mwl-ui/auth/logout" mat-menu-item>
            Logout
          </a>
        </mat-menu>
      </ng-container>
    </div>
  </div>
</div>
<mat-toolbar [class.noborder]="noborder">
  <mat-toolbar-row>
    <ng-content />
  </mat-toolbar-row>
</mat-toolbar>
