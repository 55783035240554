import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ace-dialog-footer',
    template: '<ng-content />',
    styles: [
        ':host { display: flex; width: 100% }',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogFooterComponent {
}
