import { Component, Input } from '@angular/core';
import { Status } from '../../../client/model';

@Component({
    selector: 'ace-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.css'],
})
export class StatusComponent {

    @Input()
    status: Status;

    @Input()
    label: string;

    @Input()
    leftMargin = true;
}
