<ace-dialog dialogTitle="Change Email">

    <form [formGroup]="form" class="ace-form">
        <label>
            Email <span class="hint">(required)</span>
            <input type="text" formControlName="email">
        </label>
    </form>

    <ace-dialog-footer>
        <button class="ace-button" mat-dialog-close>Cancel</button>&nbsp;&nbsp;
        <button class="ace-button primary"
                (click)="doChange()"
                [disabled]="!form.valid || form.pristine">UPDATE EMAIL</button>
    </ace-dialog-footer>
</ace-dialog>
