import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MwlClient } from '../../../client/MwlClient';
import { Product } from '../../../client/model';
import { MessageService } from '../../../shared/services/MessageService';

@Component({
    selector: 'ace-product-dialog',
    templateUrl: './product-dialog.component.html',
    styleUrls: ['./product-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDialogComponent implements OnInit {

    private data = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<ProductDialogComponent>);
    private mwlClient = inject(MwlClient);
    private messageService = inject(MessageService);

    product = signal<Product | null>(null);

    ngOnInit() {
        this.mwlClient.getProduct(this.data.id).subscribe({
            next: product => this.product.set(product),
            error: err => this.messageService.showError(err),
        });
    }
}
