<span *ngIf="status"
      class="status"
      [class.nominal]="status.state === 'OK'"
      [class.unknown]="status.state === 'UNKNOWN'"
      [class.warning]="status.state === 'WARNING'"
      [class.error]="status.state === 'ERROR'"
      [class.noLeftMargin]="!leftMargin"
      [matTooltip]="status.info">
    &nbsp;&nbsp;{{ label }}&nbsp;&nbsp;
</span>
