<div class="panel">
  <div class="icon">
    <mat-icon>info</mat-icon>
  </div>
  <div>
    <p *ngIf="headerTitle" style="margin-top: 5px">
      <strong>{{ headerTitle }}</strong>
    </p>
    <ng-content />
  </div>
</div>
