import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AceComponent } from '../../shared/page/ace-component';

@Component({
    templateUrl: './change-email-dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailDialogComponent extends AceComponent {

    private data = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<ChangeEmailDialogComponent>);

    form: FormGroup;

    constructor() {
        super();
        const user = this.data.user;
        this.form = new FormGroup({
            'email': new FormControl(user.email, Validators.required),
        });
    }

    doChange() {
        this.mwlClient.patchOwnUser({
            email: this.form.get('email')!.value,
        }).subscribe({
            next: user => {
                this.authService.user$.next(user);
                this.dialogRef.close(user);
                this.messageService.showInfo('Email changed');
            },
            error: err => this.messageService.showError(err),
        });
    }
}
