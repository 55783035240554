import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ace-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {

    @Input({ required: true })
    dialogTitle: string;

    /**
     * If enabled, the dialog fits the available container space, rather than
     * fitting its content.
     * 
     * Intended for for full-height dialogs.
     */
    @Input()
    fillHeight = false;

    @Input()
    nopadding = false;
}
