<div class="search-input" [style.width]="width">
  <input #input
         class="ace-input"
         type="text"
         [placeholder]="placeholder"
         (keydown)="onKeydown($event)">
  <div class="filter">
    <mat-icon class="icon16">{{ icon }}</mat-icon>
  </div>
  <div class="clear" *ngIf="showClear$ | async" (click)="writeValue('')">
    <mat-icon class="icon16">close</mat-icon>
  </div>
</div>
