<ace-message-bar #messageBar></ace-message-bar>

<mat-sidenav-container class="mat-typography"
                       [class.message-bar-visible]="messageBar.show$ | async">
    <mat-sidenav mode="side" [opened]="!nosidebar" [disableClose]="true" position="start">
        <ng-content select="ace-page-sidebar" />
    </mat-sidenav>

    <mat-sidenav-content>
        <ng-content select="ace-page-toolbar" />

        <div class="page-content" [class.padded]="padding">
            <ng-content />
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
