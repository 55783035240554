import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { ConfirmDialog } from './components/confirm-dialog/confirm-dialog.component';
import { EmptyMessageComponent } from './components/empty-message/empty-message.component';
import { IconActionComponent } from './components/icon-action/icon-action.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { StatusComponent } from './components/status/status.component';
import { TextActionComponent } from './components/text-action/text-action.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { DialogFooterComponent } from './dialog/dialog-footer.component';
import { DialogHeaderComponent } from './dialog/dialog-header.component';
import { DialogComponent } from './dialog/dialog.component';
import { DateTimeInput } from './forms/DateTimeInput';
import { MessageBarComponent } from './page/message-bar.component';
import { PageSidebarComponent } from './page/page-sidebar.component';
import { PageToolbarComponent } from './page/page-toolbar.component';
import { PageComponent } from './page/page.component';
import { AgoPipe } from './pipes/ago.pipe';
import { DateTimePipe } from './pipes/datetime.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';

const materialModules = [
    OverlayModule,
    CdkTableModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule,
];

const sharedComponents = [
    ConfirmDialog,
    DateTimeInput,
    DialogComponent,
    DialogFooterComponent,
    DialogHeaderComponent,
    EmptyMessageComponent,
    IconActionComponent,
    InfoMessageComponent,
    MessageBarComponent,
    PageComponent,
    PageSidebarComponent,
    PageToolbarComponent,
    SearchFilterComponent,
    StatusComponent,
    StatusIconComponent,
    TextActionComponent,
    WarningMessageComponent,
];

const pipes = [
    AgoPipe,
    DateTimePipe,
    DurationPipe,
    FormatBytesPipe,
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        materialModules,
    ],
    declarations: [
        pipes,
        sharedComponents,
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        materialModules,
        pipes,
        sharedComponents,
    ],
    providers: [
        pipes,
    ]
})
export class SharedModule {
}
