<ace-page *ngIf="user$ | async as user">
    <ace-page-toolbar>
        Profile
        <button mat-stroked-button color="primary" style="margin-left: 30px"
                (click)="openChangePasswordDialog(user)">
            <mat-icon>password</mat-icon>
            change password
        </button>
        <button mat-stroked-button color="primary" style="margin-left: 10px"
                (click)="openChangeEmailDialog(user)">
            <mat-icon>alternate_email</mat-icon>
            change email
        </button>
    </ace-page-toolbar>

    <ace-page-sidebar />

    <dl class="dl-horizontal no-lead">
        <dt>Username</dt>
        <dd>{{ user.username }}</dd>

        <dt>Display name</dt>
        <dd>{{ user.displayName || '-' }}</dd>

        <dt>Email</dt>
        <dd>{{ user.email || '-' }}</dd>
    </dl>

    <p>&nbsp;</p>

    <h4>Roles</h4>
    <div style="font-size: 12px">
        <ng-container *ngFor="let role of user.roles || []">
            {{ role || '-' }}
            <br>
        </ng-container>
    </div>

    <p>&nbsp;</p>

    <h4>Privileges</h4>
    <div style="font-size: 12px">
        <ng-container *ngFor="let privilege of user.privileges || []">
            {{ privilege || '-' }}
            <br>
        </ng-container>
    </div>
</ace-page>
