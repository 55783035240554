<mat-icon *ngIf="status?.state === 'OK'" class="status nominal"
          [matTooltip]="tooltip ?? status.info">
  check_circle
</mat-icon>
<mat-icon *ngIf="status?.state === 'UNKNOWN'" class="status unknown"
          [matTooltip]="tooltip ?? status.info">
  help
</mat-icon>
<mat-icon *ngIf="status?.state === 'WARNING'" class="status warning"
          [matTooltip]="tooltip ?? status.info">
  warning
</mat-icon>
<mat-icon *ngIf="status?.state === 'ERROR'" class="status error"
          [matTooltip]="tooltip ?? status.info">
  error
</mat-icon>
