import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OrderService } from '../services/order.service';
import { AceComponent } from './ace-component';

@Component({
    selector: 'ace-page-sidebar',
    templateUrl: './page-sidebar.component.html',
    styleUrls: ['./page-sidebar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSidebarComponent extends AceComponent {

    private orderService = inject(OrderService);

    itemCount$ = this.orderService.cartCount$;
}
