<ace-dialog dialogTitle="Change Password">

    <form [formGroup]="form" class="ace-form">
        <label>
            Password <span class="hint">(required)</span>
            <input type="password" formControlName="password">
        </label>

        <br>
        <label>
            Confirm Password <span class="hint">(required)</span>
            <input type="password" formControlName="passwordConfirmation">
        </label>
        <ace-warning-message *ngIf="form.errors?.passwordMismatch">
            Confirmation does not match password
        </ace-warning-message>
    </form>

    <ace-dialog-footer>
        <button class="ace-button" mat-dialog-close>Cancel</button>&nbsp;&nbsp;
        <button class="ace-button primary"
                (click)="doChange()"
                [disabled]="!form.valid">UPDATE PASSWORD</button>
    </ace-dialog-footer>
</ace-dialog>
