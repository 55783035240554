import { ChangeDetectorRef, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { MwlClient } from '../../client/MwlClient';
import { PrivilegeType } from '../../client/model';
import { ProductDialogComponent } from '../../product-catalog/product-list/product-dialog/product-dailog.component';
import { ConfirmDialog } from '../components/confirm-dialog/confirm-dialog.component';
import { ConfigService } from '../services/ConfigService';
import { MessageService } from '../services/MessageService';
import { AuthService } from '../services/auth.service';
import { SyncService } from '../services/sync.service';

export class AceComponent {

    protected authService = inject(AuthService);
    protected changeDetection = inject(ChangeDetectorRef);
    protected configService = inject(ConfigService);
    protected dialog = inject(MatDialog);
    protected formBuilder = inject(FormBuilder);
    protected messageService = inject(MessageService);
    protected mwlClient = inject(MwlClient);
    protected router = inject(Router);
    protected route = inject(ActivatedRoute);
    protected syncService = inject(SyncService);
    protected title = inject(Title);

    setTitle(title: string) {
        this.title.setTitle(title);
    }

    confirm(message: string): Promise<boolean> {
        return lastValueFrom(this.dialog.open(ConfirmDialog, {
            width: '600px',
            data: {
                message,
            }
        }).afterClosed());
    }

    /**
     * Sets a single query parameter, preserving the current route, and other query parameters.
     */
    setQueryParameter(param: string, value: any) {
        const queryParams: { [key: string]: any; } = {};
        queryParams[param] = value;
        this.router.navigate([], {
            replaceUrl: true,
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    showProduct(id: string) {
        this.dialog.open(ProductDialogComponent, {
            width: '70%',
            height: '100%',
            autoFocus: false,
            position: {
                right: '0',
            },
            panelClass: 'dialog-full-size',
            data: {
                id,
            },
        });
    }

    hasPrivilege(privilege: PrivilegeType) {
        return this.authService.hasPrivilege(privilege);
    }

    hasAnyPrivilege(...privileges: PrivilegeType[]) {
        return this.authService.hasAnyPrivilege(...privileges);
    }

    get arch() {
        return this.configService.getProperty('mwl.aces.role') === 'archive';
    }

    get eds() {
        return this.configService.getProperty('mwl.aces.role') === 'eds';
    }

    get config() {
        return this.configService.getConfig();
    }
}
