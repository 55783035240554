import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogData {
    message: string;
    okLabel?: string;
}

@Component({
    templateUrl: './confirm-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialog {

    readonly data = inject(MAT_DIALOG_DATA) as ConfirmDialogData;
    private dialogRef = inject(MatDialogRef<ConfirmDialog>);

    doConfirm() {
        this.dialogRef.close(true);
    }
}
