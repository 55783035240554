import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { JobProgress, StatusInformation } from '../../client/model';

export interface ProgressMessage {
    time: string;
    source: string;
    message: string;
}

@Injectable({
    providedIn: 'root',
})
export class EventStreamService implements OnDestroy {

    private evtSource: EventSource;
    public statusInformation$ = new BehaviorSubject<StatusInformation | null>(null);
    public planningUpdates$ = new Subject<any>();
    public reconfigurationUpdates$ = new BehaviorSubject<ProgressMessage[]>([]);

    private jobProgressById = new Map<number, JobProgress>();
    public jobProgress$ = new Subject<void>();

    constructor() {
        this.evtSource = new EventSource('/mwl-ui/api/event-stream', {
            withCredentials: true,
        });
        this.evtSource.addEventListener('status', event => {
            const statusInformation = JSON.parse(event.data);
            this.statusInformation$.next(statusInformation);
        });
        this.evtSource.addEventListener('planning', event => {
            const planningMessage = JSON.parse(event.data);
            this.planningUpdates$.next(planningMessage);
        });
        this.evtSource.addEventListener('yamcs_config', event => {
            const progressMessage = JSON.parse(event.data);
            this.reconfigurationUpdates$.next([
                ...this.reconfigurationUpdates$.value,
                progressMessage,
            ]);
        });
        this.evtSource.addEventListener('job_progress', event => {
            const progressMessage = JSON.parse(event.data) as JobProgress;
            this.jobProgressById.set(progressMessage.jobExecutionId, progressMessage);
            this.jobProgress$.next(); // Notify of an update to the progress map
        });
    }

    getJobProgress(jobExecutionId: number): JobProgress {
        var progress = this.jobProgressById.get(jobExecutionId);
        return progress ? progress : { jobExecutionId, progress: 0 };
    }

    ngOnDestroy() {
        this.evtSource.close();
        this.statusInformation$.complete();
        this.planningUpdates$.complete();
        this.reconfigurationUpdates$.complete();
        this.jobProgress$.complete();
    }
}
