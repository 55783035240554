<mat-toolbar class="message-bar"
             [@openClose]="show$ | async"
             *ngIf="errorMessage$ | async as message">
  <mat-toolbar-row>
    <span style="flex: 1 1 auto"></span>
    <mat-icon>report_problem</mat-icon>&nbsp; {{ message }}
    <span style="flex: 1 1 auto"></span>

    <div class="actions">
      <button mat-icon-button (click)="dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
