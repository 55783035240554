
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MessageService implements OnDestroy {

    /**
     * Active error message. Each message replaces
     * the previous one.
     */
    errorMessage$ = new BehaviorSubject<string | null>(null);

    private routerSubscription: Subscription;

    constructor(
        private snackBar: MatSnackBar,
        router: Router,
    ) {
        this.routerSubscription = router.events.pipe(
            filter((evt: any) => evt instanceof NavigationEnd),
            map((evt: any) => this.dismiss()),
        ).subscribe();
    }

    showInfo(message: string) {
        this.snackBar.open(message, 'X', {
            horizontalPosition: 'end',
            duration: 3000,
        });
    }

    showError(error: string | Error | HttpErrorResponse) {
        console.error(error);
        if (error instanceof Error) {
            this.errorMessage$.next(error.message);
        } else if (error instanceof HttpErrorResponse) {
            const serverError = error.error;
            let serverMessage = serverError?.details;
            if (!serverMessage && serverError.errors?.length) {
                serverMessage = serverError.errors[0].message;
            }
            this.errorMessage$.next(serverMessage || serverError || error.message);
        } else {
            this.errorMessage$.next(error);
        }
    }

    dismiss() {
        this.errorMessage$.next(null);
        this.snackBar.dismiss();
    }

    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
    }
}
